import { v4 as uuid } from 'uuid'

function roll(dice, bonus) {
  return {
    dice: dice,
    bonus: bonus
  }
}

function savedRoll(roll, name) {
  return {
    roll: roll,
    name: name,
    id: uuid()
  }
}

function results(roll) {
  return roll.dice.map((numberOfSides) => {
    return 1 + Math.floor(Math.random() * numberOfSides)
  })
}

function total(results, bonus) {
  return results.reduce((total, result) => {
    return total + result
  }, bonus)
}

function bonusString(bonus){
  if (bonus >= 0) {
    return "+"+bonus
  } else {
    return "-"+(bonus * -1)
  }
}

export {roll, savedRoll, results, total, bonusString}
