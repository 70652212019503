import React, {
  useEffect,
  useReducer,
  useContext,
  useRef,
  useState,
} from "react"
import DownArrow from "../assets/cheveron-down.svg"
import RightArrow from "../assets/cheveron-right.svg"
import Seo from "../components/seo"
import SavedRollsMenu from "../components/savedRollsMenu.js"
import "../utils/styles.css"
import Amplify, { API } from "aws-amplify"
import config from "../aws-exports"
import { v4 as uuid } from "uuid"
import { listDiceRolls } from "../graphql/queries"
import { createDiceRoll as CreateDiceRoll } from "../graphql/mutations"
import { onCreateDiceRoll } from "../graphql/subscriptions"
import { results, bonusString } from "../utils/rolls.js"
import { ThemeContext } from "../context/themeContext"
import { CopyToClipboard } from "react-copy-to-clipboard"
import useSiteMetadata from "../components/use-site-metadata"
import ApngComponent from "react-apng"
import rfdapng from "../../static/rfdapng.png"
import useSound from "use-sound"
import rfdSound from "../../static/rfd.mp3"
import useTimeout from "../components/useTimeout"
import { useWindowHeight } from "../components/useWindowHeight.js"

Amplify.configure(config)

const CLIENT_ID = uuid()

const RoomTemplate = ({ location }) => {
  const { siteUrl } = useSiteMetadata()
  const apngref = useRef(null)

  const { theme } = useContext(ThemeContext)

  function roll(dice, bonus) {
    return {
      dice: dice,
      bonus: bonus,
    }
  }

  const [crit, setCrit] = useState(false)
  const [renders, setRenders] = useState([])
  const [mute, setMute] = useState(false)
  const [startTimeout, setStartTimeout] = useState(false)
  const initialState = {
    dicerolls: [
      {
        id: "",
        playerId: "",
        name: "",
        savedRollName: "",
        dice: [],
        bonus: 0,
        results: [],
        ttl: 0,
      },
    ],
    compoundMode: false,
    compoundRoll: roll([], 0),
    savedRolls: new Map(),
    loading: true,
    error: false,
    copied: false,
    form: { name: "", compoundRollName: "" },
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  function reducer(state, action) {
    switch (action.type) {
      case "SET_ROLLS":
        return { ...state, dicerolls: action.dicerolls, loading: false }
      case "ADD_DICEROLL":
        return { ...state, dicerolls: [action.diceroll, ...state.dicerolls] }
      case "SET_INPUT":
        return {
          ...state,
          form: { ...state.form, [action.name]: action.value },
        }
      case "MAKE_COMPOUND_ROLL":
        return { ...state, compoundMode: true }
      case "QUIT_COMPOUND_ROLL":
        return {
          ...state,
          compoundMode: false,
          compoundRoll: roll([], 0),
          form: { ...state.form, compoundRollName: "" },
        }
      case "ADD_TO_COMPOUND_ROLL":
        return {
          ...state,
          compoundRoll: {
            ...state.compoundRoll,
            dice: [...state.compoundRoll.dice, action.numberOfSides],
          },
        }
      case "REMOVE_FROM_COMPOUND_ROLL":
        const newDice = state.compoundRoll.dice.slice()
        newDice.splice(action.index, 1)
        return {
          ...state,
          compoundRoll: { ...state.compoundRoll, dice: newDice },
        }
      case "ADD_BONUS_TO_COMPOUND_ROLL":
        return {
          ...state,
          compoundRoll: {
            ...state.compoundRoll,
            bonus: state.compoundRoll.bonus + action.bonus,
          },
        }
      case "SAVE_ROLL":
        state.savedRolls.set(action.name, action.roll)
        return { ...state }
      case "DELETE_SAVED_ROLL":
        state.savedRolls.delete(action.name)
        return { ...state }
      case "ERROR":
        return { ...state, loading: false, error: true }
      case `COPY`:
        return { ...state, copied: true }
      default:
        return state
    }
  }

  const setupApng = () => {
    setCrit(true)
  }

  const [play] = useSound(rfdSound, { soundEnabled: mute })

  useTimeout(
    () => {
      setCrit(false)
      const oldCrits = state.dicerolls
        .filter(value => value.dice[0] === 20 && value.results[0] === 20)
        .map(i => i.id)
      setRenders(oldCrits)
      setStartTimeout(false)
    },
    startTimeout === true ? 6000 : null
  )

  useEffect(() => {
    if (crit && apngref) {
      setStartTimeout(true)
    }
  }, [crit, apngref])

  useEffect(() => {
    const fetchDiceRolls = async () => {
      try {
        const diceData = await API.graphql({
          query: listDiceRolls,
          variables: { filter: { roomId: { eq: location.pathname.slice(6) } } },
        })
        dispatch({
          type: "SET_ROLLS",
          dicerolls: diceData.data.listDiceRolls.items,
        })
      } catch (err) {
        console.log("error: ", err)
        dispatch({ type: "ERROR" })
      }
    }
    fetchDiceRolls()
    const subscription = API.graphql({
      query: onCreateDiceRoll,
    }).subscribe({
      next: diceData => {
        const diceroll = diceData.value.data.onCreateDiceRoll
        if (CLIENT_ID === diceroll.playerId) return
        if (location.pathname.slice(6) !== diceroll.roomId) return
        dispatch({ type: "ADD_DICEROLL", diceroll })
        console.log("subscribed diceroll", diceroll)
        if (
          apngref.current !== undefined &&
          diceroll.dice[0] === 20 &&
          diceroll.results[0] === 20
        ) {
          play()
          setupApng()
        }
      },
    })
    return () => subscription.unsubscribe()
  }, [location, play])

  async function createDiceRoll(roll, name) {
    const { form } = state
    if (!form.name) {
      return alert("please enter a name")
    }
    const diceroll = {
      name: form.name,
      savedRollName: name,
      playerId: CLIENT_ID,
      id: uuid(),
      dice: roll.dice,
      bonus: roll.bonus,
      results: results(roll),
      roomId: location.pathname.slice(6),
      ttl: Math.floor(Date.now() / 1000),
    }
    dispatch({ type: "ADD_DICEROLL", diceroll })
    try {
      await API.graphql({
        query: CreateDiceRoll,
        variables: { input: diceroll },
      })
      if (
        apngref.current !== undefined &&
        diceroll.dice[0] === 20 &&
        diceroll.results[0] === 20
      ) {
        play()
        setupApng()
      }
    } catch (err) {
      console.log("error: ", err)
    }
  }

  function selectDie(numberOfSides) {
    if (state.compoundMode) {
      dispatch({ type: "ADD_TO_COMPOUND_ROLL", numberOfSides })
    } else {
      createDiceRoll(roll([numberOfSides], 0))
    }
  }

  function onChange(e) {
    dispatch({ type: "SET_INPUT", name: e.target.name, value: e.target.value })
  }

  function handleMuteChange(e) {
    console.log("e", e)
    setMute(e)
  }

  const buttonClass =
    "inline-flex bg-button-bg font-happydayBlackPro text-secondary-text items-center px-3 py-2 text-sm leading-4 font-medium rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"

  function saveRoll() {
    const { form } = state
    if (!form.compoundRollName) {
      return alert("please enter a name for your saved roll")
    }
    dispatch({
      type: "SAVE_ROLL",
      name: state.form.compoundRollName,
      roll: state.compoundRoll,
    })
  }

  function compoundRolls() {
    if (state.compoundMode) {
      return (
        <div>
          <div className="flex flex-col">
            <div className="flex flex-row">
              <button
                type="button"
                className="rounded-md"
                onClick={() => {
                  dispatch({ type: "QUIT_COMPOUND_ROLL" })
                }}
              >
                <DownArrow className="w-6 fill-current text-input-text bg-button-bg rounded-md" />
              </button>
              <span className="flex ml-3">
                <button
                  type="button"
                  className={buttonClass}
                  onClick={saveRoll}
                >
                  Save Roll As:
                </button>
                <input
                  onChange={onChange}
                  value={state.form.compoundRollName}
                  maxLength="25"
                  name="compoundRollName"
                  className={`${
                    theme === "planet"
                      ? "text-input-text"
                      : theme === "dark"
                      ? "text-primary"
                      : "text-primary"
                  } bg-input-bg sm:w-1-2 sm:text-sm sm:leading-5 p-2 font-happydayBlackPro`}
                  placeholder="ex. Melee Attack Roll"
                />
              </span>
            </div>
            <div className="ml-2 mt-2">Add Dice from above: ^</div>
            <div>
              {state.compoundRoll.dice.map((die, index) => {
                return (
                  <button
                    key={index}
                    type="button"
                    className={`${buttonClass} mx-2 mt-2`}
                    onClick={() => {
                      dispatch({
                        type: "REMOVE_FROM_COMPOUND_ROLL",
                        index: index,
                      })
                    }}
                  >
                    D{die}
                  </button>
                )
              })}
            </div>
            <div className="ml-2 mt-2 space-x-4">
              <span>Add/Subtract Bonus:</span>
              <button
                type="button"
                className={`${buttonClass}`}
                onClick={() => {
                  dispatch({ type: "ADD_BONUS_TO_COMPOUND_ROLL", bonus: 1 })
                }}
              >
                +1
              </button>
              <button
                type="button"
                className={buttonClass}
                onClick={() => {
                  dispatch({ type: "ADD_BONUS_TO_COMPOUND_ROLL", bonus: -1 })
                }}
              >
                -1
              </button>
              <span>{bonusString(state.compoundRoll.bonus)}</span>
            </div>
          </div>
          <button
            type="button"
            className={`${buttonClass} ml-2`}
            onClick={() => {
              return createDiceRoll(
                state.compoundRoll,
                state.form.compoundRollName
              )
            }}
          >
            Roll it!
          </button>
        </div>
      )
    } else {
      return (
        <div className="flex flex-row">
          <button
            className="mr-2 text-center w-6"
            onClick={() => {
              dispatch({ type: "MAKE_COMPOUND_ROLL" })
            }}
          >
            <RightArrow className="w-6 mr-4 text-input-text fill-current bg-button-bg rounded-md" />
          </button>
          <button
            type="button"
            className={buttonClass}
            onClick={() => {
              dispatch({ type: "MAKE_COMPOUND_ROLL" })
            }}
          >
            Make Complex Roll
          </button>
        </div>
      )
    }
  }

  const isSSR = typeof window === "undefined"
  const diceArray = [2, 3, 4, 5, 6, 7, 8, 10, 12, 14, 16, 20, 24, 30, 100]
  const height = useWindowHeight()
  return (
    <div className="max-w-7xl mx-auto" style={{ height: `${height + 400}px` }}>
      <div className="max-w-3xl mx-auto h-screen pt-10">
        <Seo title="Roll dice!" />
        <div className="flex flex-row">
          <CopyToClipboard
            text={`${siteUrl}${location.pathname}`}
            onCopy={() => dispatch({ type: "COPY" })}
          >
            <button className={`${buttonClass} ml-6`}>
              Copy link to invite others to your room
            </button>
          </CopyToClipboard>
          <div>
            {state.copied === true ? (
              <span className=" text-main-text text-lg font-happydayPro ml-2 align-middle">
                Copied!
              </span>
            ) : null}
          </div>
        </div>
        <div className="mt-4 ml-2 mr-2 -mb-2">
          {isSSR ? <div>Loading ...</div> : null}
          <div className="mt-1 relative rounded-md shadow-sm">
            <label
              htmlFor="name"
              className="flex flex-col text-sm mx-4 font-happydayBlackPro leading-5"
            >
              Your Name:
              <input
                onChange={onChange}
                value={state.form.name}
                maxLength="25"
                name="name"
                id="name"
                className={`${
                  theme === "planet"
                    ? "text-input-text"
                    : theme === "dark"
                    ? "text-primary"
                    : "text-secondary-text"
                }  w-5/6 sm:w-1/4 sm:text-base sm:leading-5 p-2 mx-4 mt-1 font-happydayBlackPro bg-input-bg`}
                placeholder="Gong Farmer"
              />
            </label>
          </div>
          <div className="flex items-center mt-2">
            <label
              htmlFor="sound"
              className="flex flex-col text-sm mx-4 font-happydayBlackPro leading-5"
            >
              Use Sound?
            </label>
            <input
              id="sound"
              name="sound"
              type="checkbox"
              onChange={e => handleMuteChange(e.currentTarget.checked)}
              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
            />
          </div>
        </div>
        <div className="m-5">
          {diceArray.map((i, index) => {
            return (
              <span
                key={index}
                className="inline-flex rounded-md shadow-sm m-2"
              >
                <button
                  type="button"
                  className={buttonClass}
                  onClick={() => selectDie(i)}
                >
                  D{i}
                </button>
              </span>
            )
          })}
        </div>
        <div className="mt-2 mb-4 ml-5">{compoundRolls()}</div>
        {SavedRollsMenu(state.savedRolls, createDiceRoll, name => {
          dispatch({ type: "DELETE_SAVED_ROLL", name: name })
        })}
        <div className="h-screen overflow-hidden overflow-y-auto pb-2">
          {state.dicerolls.map((i, index) => {
            if (i == null) {
              return <div>Null roll datum?</div>
            }
            if (i.dice != null && i.results != null && i.bonus != null) {
              return (
                <div
                  key={i.id}
                  className="my-3 mx-5 shadow overflow-x-auto sm:rounded-md"
                >
                  <ul>
                    <li>
                      <div className="px-2 py-2 sm:px-4 bg-primary-diceroll">
                        <div className="flex items-center justify-between text-secondary-text">
                          <div className="w-1/3 text-sm leading-5 font-medium font-happydayBlackPro">
                            {i.name}
                            {i.savedRollName && ": " + i.savedRollName}
                          </div>
                          <span className="flex">
                            {i.dice.map((numberOfSides, index) => {
                              return (
                                <div key={index} className="font-happydayPro">
                                  <span>D{numberOfSides}</span>:{" "}
                                  {i.results[index]}&nbsp;&nbsp;
                                </div>
                              )
                            })}
                            {i.bonus !== 0 && (
                              <span className="font-happydayBoldPro">
                                {bonusString(i.bonus)}
                                &nbsp;&nbsp;
                              </span>
                            )}
                          </span>
                          <div className="w-10 font-happydayBoldPro text-xl leading-5 sm:mt-0">
                            {i.results.reduce((total, result) => {
                              return total + result
                            }, i.bonus)}
                          </div>
                        </div>
                        {crit &&
                          i.dice[0] === 20 &&
                          i.results[0] === 20 &&
                          renders.includes(i.id) !== true && (
                            <div>
                              <ApngComponent
                                className="mx-auto"
                                ref={apngref}
                                autoPlay={true}
                                src={rfdapng}
                              />
                            </div>
                          )}
                      </div>
                    </li>
                  </ul>
                </div>
              )
            } else {
              console.log("malformed roll datum.", i)
              return <div>Malformed roll datum</div>
            }
          })}
        </div>
      </div>
    </div>
  )
}

export default RoomTemplate
