import React from "react"
import { Router } from "@reach/router"
import RoomTemplate from "../templates/dice-room"
import Layout from "../components/layout"

const Room = () => (
  <Layout>
    <Router basepath="/room">
      <RoomTemplate path="/*" />
    </Router>
  </Layout>
)

export default Room
