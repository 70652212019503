import React from "react"
import '../utils/styles.css'

const SavedRollsMenu = (savedRolls, onRoll, onDeleteRoll) => {
  return <div className="">
           {Array.from(savedRolls.entries(), entry => {
             const name = entry[0];
             const roll = entry[1];
             if (roll == null) {
               return <div>Null roll datum?</div>
             }
             return (
               <div key={name} className=" font-happydayBlackPro my-2 bg-input-bg shadow overflow-hidden sm:rounded-md">
                 <ul>
                   <li>
                     <div className="px-4 py-2 sm:px-6">
                       <div className="flex items-center justify-between">
                         <button type="button" 
                                 className="flex text-sm leading-5 font-medium text-secondary-text bg-button-bg p-2 rounded-md"
                                 onClick={() => {onRoll(roll, name)}}>
                           {name}:&nbsp;
                           {roll.dice.map((die, index) => {
                             return <span key={index} className="flex">
                                      <div className="border border-gray-300 rounded-md" key={index}>
                                        D{die}
                                      </div>
                                      &nbsp;
                                    </span>
                           })}
                           +{roll.bonus}
                         </button>
                         <button type="button"
                                 className="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mt-0"
                                 onClick={() => {onDeleteRoll(name)}}>
                           Delete roll
                         </button>
                       </div>
                     </div>
                   </li>
                 </ul>
               </div>
             )
           })
           }
         </div>
}

export default SavedRollsMenu
